import React from 'react'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

import Links from './Links'
import Logo from './Logo'

import styles from './styles.module.css'

export default function Index(props) {
  const websiteId = useWebsiteId()
  const {website} = useQuery({
    query: gql`
      query getNavbarConfigTaco($websiteId: ID) {
        website(websiteId: $websiteId) {
          _id
          logo {
            _id
            url: resizedURL(height: 200, format: "webp")
          }
          navbarConfiguration {
            logo {
              _id
              url: resizedURL(height: 200, format: "webp")
            }
            backgroundColor
            textColor
            buttonColor
            links {
              title
              path
            }
          }
        }
      }
    `,
    variables: {websiteId},
    partial: true
  })
  if (!website) return null

  const navbar = website.navbarConfiguration || {backgroundColor: '#fff', textColor: '#111'}
  const {logo} = navbar
  const finalLogo = logo || website.logo

  if (!props.open) return null

  return (
    <div className={props.isSmall ? styles.sidebarSmall : styles.sidebarSuper}>
      <div
        onClick={() => props.setOpen(false)}
        className={styles.container}
        style={{backgroundColor: navbar.backgroundColor, color: navbar.textColor}}>
        {finalLogo && <Logo config={navbar} website={website} />}
        <Links links={navbar.links} />
      </div>
      {props.isSmall ? (
        <div className={styles.shadow} onClick={() => props.setOpen(false)} />
      ) : null}
    </div>
  )
}
