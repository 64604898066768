import React from 'react'
import {MdMenu as Icon, MdRestaurant as FoodIcon} from 'react-icons/md'
import Container from '@packages/justo-parts/lib/components/Container'
import Link from 'next/link'
import {useTranslation} from 'next-i18next'

import Menu from './Menu'

import styles from './styles.module.css'

export default function Navbar(props) {
  const {website} = props
  const color = '#5cb8b2'
  const {t} = useTranslation('website', {keyPrefix: 'layout.layouts.taco.navbar'})

  return (
    <div className={styles.container} style={{color}}>
      <Container>
        <div className={styles.inner}>
          {props.isSmall ? (
            <a onClick={() => props.setOpen(!props.open)}>
              <Icon size={30} style={{marginRight: 20}} />
            </a>
          ) : null}
          <Link href="/pedir">

            <FoodIcon size={18} style={{marginRight: 10, top: -2, position: 'relative'}} />
            {t('labelShowMenu')}

          </Link>
          <div className={styles.divider} />
          {/* @ts-expect-error ts-migrate(2740) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message */}
          <Menu pointsConfiguration={website.pointsConfiguration}/>
        </div>
      </Container>
    </div>
  );
}
