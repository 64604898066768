import React from 'react'
import formatNumber from '@i18n/formatNumber'
import Link from 'next/link'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

export default function Points(props) {
  const {t} = useTranslation('website', {keyPrefix: 'layout.layouts.taco.navbar.menu.points'})
  if (!props.config) return null
  const descriptionText =
    props.user.pointsInWebsite >= 1000
      ? 'labelRedeemForDiscountsCoupons'
      : 'labelPurchaseAccumulates'
  return (
    <Link href="/canjear" legacyBehavior>
      <div className={styles.button}>
        <div className={styles.title}>
          {t('labelAmountPoints', {
            points: formatNumber(props.user.pointsInWebsite, '0,0'),
            name: props.config.name
          })}
        </div>
        <div className={styles.description}>{t(descriptionText, {name: props.config.name})}</div>
      </div>
    </Link>
  );
}
