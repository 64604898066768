import React from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  MdControlPoint as AdminIcon,
  MdExitToApp as LogoutIcon,
  MdKeyboardArrowDown as OpenMenuIcon,
  MdOpenInBrowser as LoginIcon,
  MdSettings as SettingsIcon,
  MdShopTwo as MyOrdersIcon,
  MdStore as SalesPointIcon
} from 'react-icons/md'
import logout from '@helpers/auth/logout'
import withUserId from '@helpers/auth/withUserId'
import withWebsiteId from '@helpers/websites/withWebsiteId'
import sleep from '@packages/justo-parts/lib/helpers/sleep'
import withGraphQL from '@packages/react-apollo-decorators/lib/withGraphQL'
import autobind from 'autobind-decorator'
import gql from 'graphql-tag'
import Link from 'next/link'
import {NextRouter, withRouter} from 'next/router'
import {TFunction, withTranslation} from 'next-i18next'

import Points from './Points'

import styles from './styles.module.css'

type ComponentProps = {
  open: boolean
  closeMenu: any
  toggleOpen: any
  router: NextRouter
  location: any
  me: any
  pointsConfiguration: any
  userId: string
  buttonColor: string
  onlyOptions: boolean
  t: TFunction
}

type ComponentState = {
  open: boolean // like this
}

const translatePrefix = 'layout.layouts.taco.navbar.menu'

@withWebsiteId
@withGraphQL(
  gql`
    query getMeTacoMenu($websiteId: ID) {
      me {
        _id
        name
        email
        roles
        roleInWebsite(websiteId: $websiteId) {
          _id
          role
        }
        pointsInWebsite(websiteId: $websiteId)
      }
    }
  `,
  {
    loading: null
  }
)
// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@withUserId
class User extends React.Component<ComponentProps, ComponentState> {
  state = {open: false}

  componentDidMount() {
    window.addEventListener('mouseup', this.closeMenu, false)
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.closeMenu)
  }

  @autobind
  async closeMenu(event) {
    if (!this.state.open) return true
    await sleep(100)
    this.setState({open: false})
  }

  @autobind
  toggleMenu() {
    this.setState({open: !this.state.open})
  }

  @autobind
  async logout() {
    await logout()
  }

  @autobind
  login() {
    this.props.router.push('/login')
  }

  renderAdmin() {
    if (!(this.props.me.roles && this.props.me.roles.includes('admin'))) {
      if (!this.props.me.roleInWebsite) return
      if (this.props.me.roleInWebsite.role !== 'admin') return
    }
    return (
      (<Link href="/admin" className={styles.menuLink}>

        <AdminIcon size={20} />
        <span>{this.props.t(`${translatePrefix}.labelAdmin`)}</span>

      </Link>)
    );
  }

  renderSalesPoint() {
    if (!(this.props.me.roles && this.props.me.roles.includes('admin'))) {
      if (!this.props.me.roleInWebsite) return
    }
    return (
      <a href="https://pos.getjusto.com" target="blank" className={styles.menuLink}>
        <SalesPointIcon size={20} />
        <span>{this.props.t(`${translatePrefix}.labelPointOfSale`)}</span>
      </a>
    )
  }

  renderMenu() {
    if (!this.props.me) return
    if (!this.state.open) return
    return (
      <div className={styles.menu} key="menu">
        <Link href="/pedir" passHref className={styles.account}>

          <div className={styles.name}>{this.props.me.name || 'Account'}</div>
          <div className={styles.email}>{this.props.me.email}</div>

        </Link>
        <Points user={this.props.me} config={this.props.pointsConfiguration} />
        <Link href="/pedidos" passHref className={styles.menuLink}>

          <MyOrdersIcon size={20} />
          <span>{this.props.t('fields.myOrders', {ns: 'generic'})}</span>

        </Link>
        <Link href="/settings" passHref className={styles.menuLink}>

          <SettingsIcon size={20} />
          <span>{this.props.t(`${translatePrefix}.labelMyAccount`)}</span>

        </Link>
        {this.renderAdmin()}
        {this.renderSalesPoint()}
        <div className={styles.logoutIcons}>
          <a onClick={this.logout} className={styles.menuLink}>
            <LogoutIcon size={20} />
            <div>{this.props.t(`${translatePrefix}.labelLogOut`)}</div>
          </a>
        </div>
      </div>
    );
  }

  renderIcon() {
    if (this.props.me) {
      return (
        <div className={styles.menuText} onClick={this.toggleMenu}>
          {this.props.me.name || 'Account'} <OpenMenuIcon />
        </div>
      )
    } else if (!this.props.userId) {
      return (
        <div className={styles.menuText} onClick={this.login}>
          {this.props.t(`${translatePrefix}.labelLogIn`)} <LoginIcon />
        </div>
      )
    }
  }

  render() {
    const color = '#5cb8b2'
    return (
      <div className={styles.container} style={{color}}>
        {this.renderIcon()}
        <ReactCSSTransitionGroup
          transitionName="user-menu"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={200}>
          {this.renderMenu()}
        </ReactCSSTransitionGroup>
      </div>
    )
  }
}

export default withTranslation()(withRouter<ComponentProps>(User))
