import React, {useState} from 'react'
import ClientSuspense from '@components/ClientSuspense'
import useWidth from '@hooks/useWidth'
import useInitialData from '@page-components/Layout/useInitialData'

import Navbar from './Navbar'
import Sidebar from './Sidebar'

import styles from './styles.module.css'


export default function TacoLayout(props: {children: React.ReactNode}) {
  const {website} = useInitialData()
  const width = useWidth()
  const [stateOpen, setOpen] = useState()
  const isSmall = width < 1100
  const open = !isSmall || stateOpen
  return (
    <div className={styles.container}>
      <ClientSuspense fallback={null}>
        <Sidebar isSmall={isSmall} open={open} setOpen={setOpen} />
      </ClientSuspense>
      <div className={styles.content}>
        <Navbar isSmall={isSmall} open={open} setOpen={setOpen} website={website}/>
        {props.children}
      </div>
    </div>
  )
}
