import React from 'react'
import Link from 'next/link'

import styles from './styles.module.css'

const renderLinks = function (links) {
  return links.map((link, index) => {
    if (link.path.startsWith('/')) {
      return (
        <Link href={link.path} key={index} className={styles.link}>
          {link.title}
        </Link>
      );
    } else {
      return (
        <a
          href={link.path}
          target="_blank"
          key={index}
          className={styles.link}
          rel="noopener noreferrer">
          {link.title}
        </a>
      )
    }
  });
}

export default function SidebarLinks({links}) {
  if (!links || !links.length) return null
  return <div className={styles.container}>{renderLinks(links)}</div>
}
