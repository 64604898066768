import React from 'react'
import useResizedImageURL from '@hooks/useResizedImageURL'
import Link from 'next/link'

import styles from './styles.module.css'

export default function Logo(props) {
  const {website, config} = props
  const {logo, backgroundColor} = config
  const finalLogo = logo || website.logo
  const url = useResizedImageURL({
    fileId: finalLogo._id,
    width: 500,
    background: backgroundColor || '#fff'
  })

  if (!url) return null
  return (
    <div className={styles.container}>
      <Link href="/" legacyBehavior>
        <img src={url} alt="Logo" />
      </Link>
    </div>
  );
}
