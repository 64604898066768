import React from 'react'

import withSession from './withSession'

export interface WithUserIdProps {
  session: any
}

export default function WithUserId(ComposedComponent) {
  class WithUserId extends React.Component<WithUserIdProps> {
    render() {
      const {userId} = this.props.session
      return <ComposedComponent {...this.props} userId={userId} />
    }
  }

  return withSession(WithUserId)
}
